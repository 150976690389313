/* eslint-disable max-len */
import { Consent } from "cookieconsent";
import styles from "../scss/vars.module.scss";
interface ConsentP extends Consent {
  status: {
    allow: 'allow';
    deny: 'deny';
    dismiss: 'dismiss';
  },
  utils: {
    getCookie(name: string): string;
  }
}
declare global {
  interface Window {
    cookieconsent?: ConsentP;
    dataLayer?: Array<any>;
    gtag?: (...args: any[]) => void;
  }
}
const PROD_GAID = 'G-053EXN64G6';
const DEV_GAID = 'G-3D4G0DGHM4';

const GAID = (window.location.host.indexOf('dev') > -1 || window.location.host.includes('localhost')) ? DEV_GAID : PROD_GAID;
const COOKIE_CONSENTS_EXPIRATION_VALUE = 28; // Days

export const isConsentReceived = (): boolean => {
  const { cookieconsent } = window || {};

  return !!cookieconsent && cookieconsent.utils.getCookie('cookieconsent_status') === cookieconsent.status.allow;
};

let isConsentInitialised = false;

const CookieConsentInit = (): void => {
  if (isConsentInitialised) return;

  const addAnylitics = () => {
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error direct access to window by string id
    delete window[`ga-disable-${GAID}`];
    if (window.gtag === undefined) {
      window.gtag = function () {
        if (window.dataLayer === undefined) {
          window.dataLayer = [];
        }
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', GAID, {
        'send_page_view': true,
      });
    }
    window.gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'granted'
    });
    const srcUrl = `https://www.googletagmanager.com/gtag/js?id=${GAID}`;
    const scriptTags = document.querySelectorAll('script');
    const scriptEl = Array.from(scriptTags).find(v => {
      return v.src === srcUrl;
    });
    if (!scriptEl) {
      const s = document.createElement('script');
      s.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GAID}`);
      s.async = true;
      document.head.appendChild(s);
    }
  };

  const cc = window.cookieconsent as Consent;
  cc?.initialise?.(
    {
      palette: {
        popup: {
          background: styles.marine,
        },
        button: {
          background: styles.aviator,
        },
      },
      content: {
        // message: 'We use cookies and other tools to collect information you provide to us and to capture and record your interaction with our site and use such information for analytics. We share this information with our analytics partners. By clicking "Allow Cookies” you consent to our collection, use, and disclosure of such information and to our Terms and Conditions. For more information about our data processing practices, please see our Privacy Notice.',
        // link: ' Terms and Conditions',
        // href: 'https://www.sutrotower.com/terms-and-conditions',
        // close: '&#x274c;',
        // policy: 'Privacy Notice',
        // target: 'https://www.sutrotower.com/privacy-policy',
      },
      elements: {
        message: `<div class="cc-dialog-text">We use cookies and other tools to collect information you provide to us and to capture and record your
        interaction with our site and use such information for analytics. We share this information with our analytics 
        partners. By clicking "Allow Cookies” you consent to our collection, use, and disclosure of such information and
        to our <a href="https://www.sutrotower.com/terms-and-conditions">Terms and Conditions</a>. 
        For more information about our data processing practices, please see our <a href="https://www.sutrotower.com/privacy-policy">Privacy Notice.</a></div>`,
      },
      cookie: {
        expiryDays: COOKIE_CONSENTS_EXPIRATION_VALUE,
      },
      showLink: false,
      type: 'opt-in',
      onInitialise: (status) => {
        if (status === window.cookieconsent?.status.allow) {
          // add cookie consent dependent code that should run on start up
          addAnylitics();
        }
      },
      onStatusChange: (status) => {
        if (status === window.cookieconsent?.status.allow) {
          // add cookie consent dependent code that should run any time the status is changed
          addAnylitics();
        } else if (status === window.cookieconsent?.status.deny) {
          if (window.gtag) {
            window.gtag('consent', 'default', {
              'ad_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied',
              'analytics_storage': 'denied'
            });
          }
          // @ts-expect-error direct access to window by string id
          window[`ga-disable-${GAID}`] = true;
        }
      },
    },
    (popup) => {
      isConsentInitialised = !!popup;
    },
  );
};

export default CookieConsentInit;